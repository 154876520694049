/* import __COLOCATED_TEMPLATE__ from './file.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';

export interface Upload {
  id: number | string;
  name: string;
  progress: number;
  url?: string;
  contentType: string;
  isReadOnly?: boolean;
}

interface Args {
  value: Upload[];
  descriptor: TicketDescriptor;
  onUpdateValue: (value: unknown) => unknown;
  isReadOnly?: boolean;
  hasError?: boolean;
}

interface Signature {
  Args: Args;
}

export default class File extends Component<Signature> {
  @service declare session: Session;

  @tracked uploads: Upload[] = [];
  @tracked maxNumberOfFiles = 1;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args?.value && typeof this.args.value[0] === 'object') {
      this.uploads = this.args?.value;
    }

    if (this.args.descriptor.allowMultipleValues) {
      this.maxNumberOfFiles = 5;
    }
  }

  @action
  onDelete(uploadToDelete: Upload) {
    this.uploads = this.uploads.filter((upload: Upload) => {
      return uploadToDelete.id !== upload.id;
    });

    if (this.session.workspace.canUseOptimisticUpdates) {
      this.args.onUpdateValue(this.uploads);
    } else {
      this.args.onUpdateValue(this.uploads.map((upload: Upload) => upload.id));
    }
  }

  @action
  onUpdateValue(newUploads: Upload[]) {
    this.uploads = [...this.uploads, ...newUploads].uniqBy('id');
    if (this.session.workspace.canUseOptimisticUpdates) {
      this.args.onUpdateValue(this.uploads);
    } else {
      this.args.onUpdateValue(this.uploads.map((upload: Upload) => upload.id));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::File': typeof File;
    'inbox2/ticket-attribute/file': typeof File;
  }
}
