/* import __COLOCATED_TEMPLATE__ from './phone-call-alert.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type TwilioService from 'embercom/services/twilio-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import { tracked } from '@glimmer/tracking';
import type IntercomCallService from 'embercom/services/intercom-call-service';

interface Args {}

interface Signature {
  Args: Args;
}

export default class PhoneCallAlertComponent extends Component<Signature> {
  @service declare twilioService: TwilioService;
  @service declare intercomCallService: IntercomCallService;
  @service declare intl: IntlService;
  @tracked selectedPhoneNumber: PhoneNumber | undefined;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    let phoneNumbers = this.intercomCallService.workspacePhoneNumbers;
    this.selectedPhoneNumber = phoneNumbers?.firstObject;
  }

  get participants() {
    return this.twilioService.participants;
  }

  get userSummary() {
    return this.twilioService.userSummary;
  }

  get incomingPhoneAlertMessage() {
    if (this.twilioService.isIncomingCallConference) {
      if (this.twilioService.participants.length < 2) {
        return this.intl.t(
          'calling.incoming-phone-call-modal.conference-call-unknown-participants',
        );
      }

      let participantList = this.participants
        .slice(0, -1)
        .map((participant) => participant.displayLabel)
        .join(', ');
      let lastParticipant = this.participants.lastObject?.displayLabel;

      return this.intl.t('calling.incoming-phone-call-modal.conference-call-known-participants', {
        participantList,
        lastParticipant,
      });
    }

    if (!this.userSummary) {
      throw new Error('User summary is not defined');
    } else if (this.isCallerIdBlocked(this.userSummary.phone) === true) {
      return this.intl.t('calling.incoming-phone-call-modal.blocked-number');
    } else if (!this.userSummary.name) {
      if (this.twilioService.isIncomingCallback) {
        return this.intl.t('calling.incoming-phone-call-modal.callback-unknown-number', {
          number: this.userSummary.phone,
        });
      }
      if (
        this.twilioService.isIncomingCallTransfer ||
        this.twilioService.isIncomingCallWarmTransfer
      ) {
        return this.intl.t('calling.incoming-phone-call-modal.transfer-unknown-number', {
          number: this.userSummary.phone,
        });
      }
      return this.intl.t('calling.incoming-phone-call-modal.unknown-number', {
        number: this.userSummary.phone,
      });
    }
    if (
      this.twilioService.isIncomingCallTransfer ||
      this.twilioService.isIncomingCallWarmTransfer
    ) {
      return this.intl.t('calling.incoming-phone-call-modal.transfer-known-number', {
        name: this.userSummary.name,
        number: this.userSummary.phone,
      });
    }

    if (this.twilioService.isIncomingCallback) {
      return this.intl.t('calling.incoming-phone-call-modal.callback-known-number', {
        name: this.userSummary.name,
        number: this.userSummary.phone,
      });
    }
    return this.intl.t('calling.incoming-phone-call-modal.known-number', {
      name: this.userSummary.name,
      number: this.userSummary.phone,
    });
  }

  get currentPhoneNumberTitle() {
    return this.intl.t('calling.modal.current-phone-number', {
      number: this.twilioService.workspacePhoneNumber,
      country: this.twilioService.calledNumberCountryCode,
    });
  }

  isCallerIdBlocked(phone: string | undefined) {
    return phone !== undefined && phone === '+266696687';
  }

  @action
  async acceptCall() {
    await this.twilioService.acceptCall();
  }

  @action
  async onNumberSelectionChange(option: PhoneNumber) {
    this.selectedPhoneNumber = option;
  }

  @action
  rejectCall() {
    this.twilioService.rejectCall();
  }

  @action
  navigateToConversation() {
    this.twilioService.navigateToConversation();
  }

  @action
  ignoreCallback() {
    this.twilioService.ignoreCallback();
  }

  @action
  async acceptCallback() {
    this.twilioService.acceptCallback();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneCallAlert': typeof PhoneCallAlertComponent;
    'inbox2/phone-call-alert': typeof PhoneCallAlertComponent;
  }
}
