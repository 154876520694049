/* import __COLOCATED_TEMPLATE__ from './paused-sla.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

interface Arguments {
  remainingSeconds: number;
  isInteractive?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLElement;
}

export default class PausedSla extends Component<Signature> {
  @service declare intl: IntlService;

  get formattedDuration() {
    // In order for format-duration to display negative numbers correctly, we need to show the absolute value
    let remainingSeconds = Math.abs(this.args.remainingSeconds);
    return this.intl.formatDuration(remainingSeconds).split(' ')[0];
  }

  get statusStyles() {
    return this.args.remainingSeconds > 0
      ? 'text-muted bg-neutral-container  border-neutral-border '
      : 'text-default bg-error-container  border-error-border ';
  }

  get interactiveStyles() {
    if (!this.args.isInteractive) {
      return '';
    }

    return 'hover:border-neutral-border focus:border-neutral-border  ';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Sla::PausedSla': typeof PausedSla;
    'inbox2/sla/paused-sla': typeof PausedSla;
  }
}
