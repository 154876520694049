/* import __COLOCATED_TEMPLATE__ from './participant-row.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';

interface Args {
  model: UserSummary | AdminWithPermissions | SenderEmailAddressSummary;
  onDelete?: (participant: UserSummary) => void;
  isInvalid?: boolean;
}

export default class ParticipantRowComponent extends Component<Args> {
  get stateClasses() {
    if (this.args.isInvalid) {
      return this.invalidClasses;
    }
    return this.defaultClasses;
  }

  private get invalidClasses() {
    return `bg-error-container text-error  `;
  }

  private get defaultClasses() {
    return ' text-default   border border-neutral-border ';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ManageParticipants::ParticipantRow': typeof ParticipantRowComponent;
    'inbox2/manage-participants/participant-row': typeof ParticipantRowComponent;
  }
}
