/* import __COLOCATED_TEMPLATE__ from './active-sla.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';

interface Arguments {
  nextBreachAt: Date;
  isInteractive?: boolean;
}

interface Signature {
  Args: Arguments;
  Element: HTMLElement;
}

enum Status {
  Due,
  NearlyDue,
  Overdue,
}

const FIVE_MINS = 5 * 60 * 1000;

export default class ActiveSla extends Component<Signature> {
  get status(): Status | undefined {
    let now = new Date();

    if (this.args.nextBreachAt < now) {
      return Status.Overdue;
    }

    let isNearlyDue = now.getTime() + FIVE_MINS > this.args.nextBreachAt.getTime();
    if (isNearlyDue) {
      return Status.NearlyDue;
    }

    return Status.Due;
  }

  get percentageFilled(): number {
    return {
      [Status.Due]: 10,
      [Status.NearlyDue]: 60,
      [Status.Overdue]: 0,
    }[this.status ?? Status.Due];
  }

  get statusStyles() {
    switch (this.status) {
      case Status.Due:
        return 'text-muted bg-neutral-container border-neutral-border ';
      case Status.NearlyDue:
        return 'text-default bg-notice-container border-notice-border ';
      case Status.Overdue:
        return 'text-default bg-error-container border-error-border ';
      default:
        return '';
    }
  }

  get interactiveStyles() {
    if (!this.args.isInteractive) {
      return '';
    }

    switch (this.status) {
      case Status.Due:
        return 'hover:border-neutral-border focus:border-neutral-border  ';
      case Status.Overdue:
        return 'hover:border-error-border focus:border-error-border  ';
      case Status.NearlyDue:
        return 'hover:border-notice-border-emphasis focus:border-notice-border-emphasis  ';
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::Sla::ActiveSla': typeof ActiveSla;
    'inbox2/sla/active-sla': typeof ActiveSla;
  }
}
