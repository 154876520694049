/* import __COLOCATED_TEMPLATE__ from './ticket-details-header.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import type ParticipantUserSummary from 'embercom/objects/inbox/participant-user-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type CompanySummary from 'embercom/objects/inbox/company-summary';
import { localCopy } from 'tracked-toolbox';

export interface Args {
  conversation: Conversation;
  newTicketCategory?: TicketCategory;
  existingRecord: boolean;
  onCompanySelected: (companyId: string | null, isDefaultSelection: boolean) => void;
  isReadOnly?: boolean;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class TicketDetailsHeader extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @localCopy('args.conversation.companyId') associatedCompanyId: string | undefined;
  @tracked associatedCompanyWasDeleted = false;

  get mappedOtherParticipants() {
    if (this.args.conversation.otherParticipants === undefined) {
      return [];
    }

    return this.args.conversation.otherParticipants.map((participant: ParticipantUserSummary) => ({
      userSummary: participant,
      userDetailToDisplay: this.userDetailToDisplay(participant),
    }));
  }

  get ticketCategory() {
    return this.args.newTicketCategory ?? this.args.conversation.ticketCategory;
  }

  get isTrackerTicket() {
    return this.ticketCategory === TicketCategory.Tracker;
  }

  get categoryNameIntlKey() {
    return `inbox.ticket-details-header.categories.names.${this.ticketCategory}`;
  }

  get categoryTooltipIntlKey() {
    return `inbox.ticket-details-header.categories.tooltips.${this.ticketCategory}`;
  }

  get feedbackTooltip() {
    return `inbox.ticket-details-header.categories.tooltips.feedback`;
  }

  get categoryIcon() {
    if (this.ticketCategory === TicketCategory.Request) {
      return {
        set: 'standard',
        name: 'ticket',
      };
    } else if (this.ticketCategory === TicketCategory.Tracker) {
      return {
        set: 'standard',
        name: 'tracker',
      };
    }

    return {
      set: 'standard',
      name: 'back-office',
    };
  }

  get firstParticipantDetailToDisplay(): string {
    if (this.ticketOwner === undefined) {
      return '';
    }

    return this.userDetailToDisplay(this.ticketOwner);
  }

  get ticketId() {
    return this.args.conversation.ticketId?.toString();
  }

  get isCreatingTicket() {
    return this.args.newTicketCategory !== undefined;
  }

  get ticketOwner() {
    return this.args.conversation?.firstParticipant;
  }

  get userCompanies(): Array<CompanySummary> {
    let deduplicatedCompanies = this.args.conversation.participantSummaries
      // concatenate the lists of companies
      .reduce((list, user) => list.concat(user.companies || []), new Array<CompanySummary>())
      // deduplicate the elements in the list
      .reduce((hm, company) => (hm.set(company.id, company), hm), new Map<string, CompanySummary>())
      .values();

    return Array.from(deduplicatedCompanies);
  }

  get shouldShowCompanySelect() {
    return this.userCompanies.length > 0 || this.hasAssociatedCompany;
  }

  get userIsInCompany() {
    if (!this.hasAssociatedCompany || this.associatedCompanyWasDeleted === true) {
      return true;
    }

    let userCompanyIds = this.ticketOwner.companyIds;
    if (userCompanyIds === undefined) {
      return false;
    }

    return userCompanyIds.includes(this.associatedCompanyId!);
  }

  get userAssociatedTooltip() {
    return this.intl.t('inbox.ticket-details-header.tooltip-content.user-associated');
  }

  get usersAssociatedTooltip() {
    return this.intl.t('inbox.ticket-details-header.tooltip-content.users-associated');
  }

  get hasAssociatedCompany(): boolean {
    return this.associatedCompanyId !== null && this.associatedCompanyId !== undefined;
  }

  get userRemovedFromCompanyTooltip() {
    return this.intl.t('inbox.ticket-details-header.tooltip-content.user-removed-from-company');
  }

  userDetailToDisplay(userSummary: ParticipantUserSummary | UserSummary): string {
    if (userSummary?.name) {
      return userSummary.name;
    } else if (userSummary?.email) {
      return userSummary.email;
    } else if (userSummary?.pseudonym) {
      return userSummary.pseudonym;
    } else {
      return '';
    }
  }

  @action
  updateAssociatedCompanyWasDeleted(deleted: boolean) {
    this.associatedCompanyWasDeleted = deleted;
  }

  @action
  updateAssociatedCompanyId(associatedCompanyId: string | undefined) {
    this.associatedCompanyId = associatedCompanyId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketDetailsHeader': typeof TicketDetailsHeader;
    'inbox2/ticket-details-header': typeof TicketDetailsHeader;
  }
}
