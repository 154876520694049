/* import __COLOCATED_TEMPLATE__ from './onboarding-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  type OnboardingType,
  type StepId,
  getActiveStepKey,
} from 'embercom/lib/inbox2/onboarding/steps';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type Inbox2OptIn from 'embercom/services/inbox2-opt-in';
import type RouterService from '@ember/routing/router-service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import storage from 'embercom/vendor/intercom/storage';
import type IntlService from 'embercom/services/intl';
import type ThemeService from 'embercom/services/theme-service';

interface Args {
  type: OnboardingType;
  from: string;
}

interface Signature {
  Args: Args;
}

enum Step {
  SelectTheme = 'select-theme',
  SelectLanguage = 'select-language',
  LearnShortcuts = 'learn-shortcuts',
}

export default class Inbox2OnboardingWrapper extends Component<Signature> {
  @service declare session: Session;
  @service declare inbox2OptIn: Inbox2OptIn;
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare onboardingHomeExternalStepService: any;
  @service declare intl: IntlService;
  @service declare themeService: ThemeService;

  @tracked step = this.activeStepId ? Step.LearnShortcuts : Step.SelectTheme;

  get activeStepId() {
    return storage.get(getActiveStepKey(this.args.type, this.session.workspace.id));
  }

  @action goBack() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'completed',
      object: 'onboarding',
      place: 'onboarding',
      section: 'onboarding',
      used_exit_button: true,
    });

    if (this.args.from === 'onboarding') {
      this.router.transitionTo('apps.app.home', this.session.workspace.id);
    }
    this.transitionToInbox2();
  }

  @action goToSelectLanguage() {
    this.step = Step.SelectLanguage;
  }

  @action goToLearnShortcuts() {
    this.step = Step.LearnShortcuts;
  }

  @action async onFinish() {
    await this.inbox2OptIn.optIn();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'completed',
      object: 'onboarding',
      place: 'onboarding',
      section: 'onboarding',
    });

    if (this.args.from === 'onboarding') {
      if (this.onboardingHomeExternalStepService.isExternalStepRunning('reply_in_inbox')) {
        this.onboardingHomeExternalStepService.completeExternalStep();
      }
      this.router.transitionTo('apps.app.home', this.session.workspace.id);
    } else {
      // Before taking the user to Inbox, we should fetch features again, just
      // to make sure we don't have any stale feature flags.
      this.session.workspace.fetchFeatures();
      this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.All);
    }
  }

  @action async switchLocale(locale: string) {
    await this.intl.switchLocale(locale);
  }

  @action onStepChange(id: StepId) {
    return storage.set(getActiveStepKey(this.args.type, this.session.workspace.id), id);
  }

  get showExit(): boolean {
    return !this.showSkipAll;
  }

  @action async onSkipAll() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'skip_all',
      place: 'onboarding',
      section: 'onboarding',
    });
    storage.set(`onboarding-skip-all:${this.session.workspace.id}`, true);
    if (!this.inbox2OptIn.hasCompletedOnboarding) {
      await this.inbox2OptIn.optIn();
    }
    this.transitionToInbox2();
  }

  private transitionToInbox2() {
    this.router.transitionTo('inbox.workspace.inbox.inbox', InboxCategory.Shared, InboxType.All);
  }

  get isNewWorkspace() {
    return this.inbox2OptIn.canUseFeature('inbox_2_new_user_onboarding');
  }

  get showSkipAll(): boolean {
    return !this.inbox2OptIn.isOnboardingCompleted;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::OnboardingWrapper': typeof Inbox2OnboardingWrapper;
    'inbox2/onboarding-wrapper': typeof Inbox2OnboardingWrapper;
  }
}
