/* import __COLOCATED_TEMPLATE__ from './completable-header-content.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

interface Args {
  isComplete: boolean;
  isOptional?: boolean;
}

interface Signature {
  Args: Args;
  Element: any;
  Blocks: any;
}

export default class CompletableHeaderContent extends Component<Signature> {
  get imageSrc() {
    if (this.args.isComplete) {
      return assetUrl('/assets/images/settings/messenger-installation-new/success.svg');
    } else {
      return this.args.isOptional
        ? assetUrl('/assets/images/settings/messenger-installation-new/dashed-ellipse.svg')
        : assetUrl('/assets/images/settings/messenger-installation-new/ellipse.svg');
    }
  }

  get progressColor() {
    return this.args.isComplete ? 'border-success-border' : 'border-neutral-border';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InstallationNew::CompletableHeaderContent': typeof CompletableHeaderContent;
    'installation-new/completable-header-content': typeof CompletableHeaderContent;
  }
}
