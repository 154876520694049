/* import __COLOCATED_TEMPLATE__ from './ticket-attributes-tab.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type Conversation from 'embercom/objects/inbox/conversation';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { TicketType } from 'embercom/objects/inbox/ticket';
import { TicketTypeResource } from 'embercom/components/inbox2/ticket-type-resource';
import { Resource, useResource } from 'ember-resources';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import type TicketAttributeSummary from 'embercom/objects/inbox/ticket-attribute-summary';
import ConversationAttributeSummary, {
  type ConversationAttributeSummaryWireFormat,
} from 'embercom/objects/inbox/conversation-attribute-summary';
import { modifier } from 'ember-modifier';

export class TicketAttributesTabComponentResource extends Resource {
  @tracked formSubmitted = false;

  constructor(owner: any, args: any, previous: any) {
    super(owner, args);

    let conversation = args.named.conversation;

    if (conversation && conversation.id !== previous?.args?.conversation?.id) {
      this.formSubmitted = false;
    }
  }

  submitForm() {
    this.formSubmitted = true;
  }
}

interface Args {
  conversation: Conversation;
  ticketTypeId?: number;
  setTicketTypeToAddToConversation: (ticketTypeId?: number) => void;
  viewMode: 'stacked' | '2-column';
}

interface Signature {
  Args: Args;
}

export default class TicketAttributesTab extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare snackbar: Snackbar;

  @tracked visibleToUser = false;
  @tracked labelRowSize = 'w-1/4';

  readonly ticketTypeResource = useResource(this, TicketTypeResource, () => ({
    ticketTypeId: this.args.ticketTypeId || this.args.conversation.ticketType?.id,
    conversation: this.args.conversation,
  }));
  ticketAttributesTabComponentResource = useResource(
    this,
    TicketAttributesTabComponentResource,
    () => ({
      conversation: this.args.conversation,
    }),
  );

  setLabelWidth = modifier((element: Element) => {
    if (element.clientWidth > 1000) {
      this.labelRowSize = 'w-1/4';
    } else {
      this.labelRowSize = 'w-1/3';
    }
  });

  get ticketType(): TicketType | undefined {
    return this.args.conversation.ticketType || this.ticketTypeResource.selectedTicketType;
  }

  get isAddingTicketTypeToConversation(): boolean {
    return this.args.conversation.ticketType === undefined;
  }

  @action onVisibilityChange(visibility_change: boolean) {
    this.visibleToUser = visibility_change;
  }

  @action
  cancelAddTicketToConversation() {
    this.args.setTicketTypeToAddToConversation(undefined);
  }

  @task({ drop: true })
  *addTicketToConversation() {
    this.ticketAttributesTabComponentResource.submitForm();

    if (this.args.ticketTypeId === undefined) {
      return;
    }

    if (this.ticketTypeResource.ticketAttributes.any((attr) => attr.showErrorOnCreate)) {
      this.snackbar.notifyError(
        this.intl.t('inbox.new-conversation.errors.invalid-ticket-attributes'),
      );
      return;
    }

    let ticketAttributes = this.ticketTypeResource.ticketAttributes?.map(
      (attributeSummary: TicketAttributeSummary) => {
        return {
          descriptor_id: attributeSummary.descriptor.id,
          value: attributeSummary.serializedValue,
        };
      },
    );

    try {
      let { ticket_type, attributes: attributesSerialised } =
        yield this.inboxApi.createTicketFromConversation(
          this.args.conversation,
          this.args.ticketTypeId,
          ticketAttributes,
          this.visibleToUser,
        );

      let ticketType = TicketType.deserialize(ticket_type);
      let attributes = attributesSerialised.map(
        (attribute: ConversationAttributeSummaryWireFormat) =>
          ConversationAttributeSummary.deserialize(attribute),
      );
      this.args.conversation.addTicket(ticketType, attributes);

      this.cancelAddTicketToConversation();
    } catch (e) {
      let errorText =
        e?.jqXHR?.responseJSON?.errors ||
        this.intl.t('inbox.notifications.ticket-added-to-conversation');
      this.snackbar.notifyError(errorText);
    }
  }

  @task({ drop: true })
  *updateAttribute(attribute: TicketAttributeSummary) {
    if (attribute.isUpdated) {
      yield this.inboxApi.updateAttribute(this.args.conversation.id, attribute);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttributesTab': typeof TicketAttributesTab;
    'inbox2/ticket-attributes-tab': typeof TicketAttributesTab;
  }
}
