/* import __COLOCATED_TEMPLATE__ from './ticket-visibility-details.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type TicketDescriptor } from 'embercom/objects/inbox/ticket';
import { type DataType } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { task } from 'ember-concurrency-decorators';
import type InboxApi from 'embercom/services/inbox-api';
import type Snackbar from 'embercom/services/snackbar';
import type IntlService from 'embercom/services/intl';
import type InboxState from 'embercom/services/inbox-state';
import { InboxEvents } from 'embercom/services/inbox-state';
import { type ConversationResource } from './conversation-resource';
import type Session from 'embercom/services/session';

interface Args {
  conversationResource: ConversationResource;
  isReadOnly?: boolean;
}

interface Signature {
  Args: Args;
}

export default class TicketVisibilityDetails extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxApi: InboxApi;
  @service declare snackbar: Snackbar;
  @service declare intl: IntlService;
  @service declare inboxState: InboxState;

  @tracked showVisibilityModal = false;

  get conversation() {
    return this.args.conversationResource.conversation;
  }

  get isCustomerTicket() {
    return this.conversation.isCustomerTicket;
  }

  get isBackOfficeTicket() {
    return this.conversation.isBackOfficeTicket;
  }

  get isTrackerTicket() {
    return this.conversation.isTrackerTicket;
  }

  get isShareableBackOfficeTicket() {
    return this.isBackOfficeTicket && this.canMakeVisibleToUser;
  }

  get userVisibleAttributes() {
    return this.conversation?.ticketType?.descriptors
      ?.filter((descriptor: TicketDescriptor) => {
        return descriptor.visibleToUsers && !descriptor.archived;
      })
      .map((descriptor: TicketDescriptor) => ({
        ...descriptor,
        attributeIconName: this.attributeIconName(descriptor.dataType, descriptor.multiline),
      }));
  }

  get hasUserVisibleAttributes() {
    return this.userVisibleAttributes && this.userVisibleAttributes.length > 0;
  }

  get visibleToUser() {
    return this.conversation?.visibleToUser;
  }

  get canMakeVisibleToUser() {
    return this.conversation?.canMakeVisible;
  }

  get isTicketTypeInternal() {
    return this.conversation?.ticketType?.internal;
  }

  get hasParent() {
    return !!this.conversation?.parent;
  }

  get sharingTooltipContent() {
    let contentKey;

    if (this.visibleToUser) {
      if (this.isCustomerTicket) {
        contentKey = 'customer-ticket-always-shared';
      } else {
        contentKey = 'back-office-ticket-is-shared';
      }
    } else if (this.canMakeVisibleToUser) {
      contentKey = 'back-office-ticket-can-be-shared';
    } else if (this.isTrackerTicket || this.isTicketTypeInternal) {
      contentKey = 'ticket-cannot-be-shared';
    } else if (this.hasParent) {
      contentKey = 'existing-shared-ticket-cannot-be-shared';
    } else {
      contentKey = 'standalone-ticket-cannot-be-shared';
    }

    return this.intl.t(`inbox.ticket-details-header.tooltip-content.${contentKey}`);
  }

  get shouldShowUserVisibleAttributes() {
    return this.hasUserVisibleAttributes && (this.visibleToUser || this.canMakeVisibleToUser);
  }

  get visibleAttributesTooltipHeader() {
    let content = this.intl.t('inbox.ticket-details-header.tooltip-content.visible-attributes');

    if (this.isShareableBackOfficeTicket && !this.visibleToUser) {
      return `${content} - ${this.intl.t('inbox.ticket-details-header.tooltip-content.if-shared')}`;
    }

    return content;
  }

  get shouldShowSharingButton() {
    return !this.visibleToUser && this.canMakeVisibleToUser && !this.session.showLightInbox;
  }

  @task({ drop: true })
  *makeTicketVisible() {
    try {
      yield this.inboxApi.shareTicketWithUser(this.conversation.id);
      this.args.conversationResource.reload();
      this.showVisibilityModal = !this.showVisibilityModal;
      this.inboxState.trigger(InboxEvents.TicketVisibilityUpdated, this.conversation.visibleToUser);
    } catch {
      this.snackbar.notifyError(
        this.intl.t('inbox.conversation-header.ticket-attributes-tab.make-ticket-visible-error'),
      );
    }
  }

  attributeIconName(attribute: DataType, isMultiline: boolean | null) {
    if (attribute === 'integer' || attribute === 'decimal') {
      return 'numbers';
    } else if (attribute === 'datetime') {
      return 'calendar';
    } else if (attribute === 'list') {
      return 'list';
    } else if (attribute === 'boolean') {
      return 'boolean';
    } else if (attribute === 'files') {
      return 'attachment';
    } else if (attribute === 'string' && isMultiline) {
      return 'long-text';
    } else {
      return 'short-text';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketVisibilityDetails': typeof TicketVisibilityDetails;
    'inbox2/ticket-visibility-details': typeof TicketVisibilityDetails;
  }
}
