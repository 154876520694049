/* import __COLOCATED_TEMPLATE__ from './sender-selector.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type InboxApi from 'embercom/services/inbox-api';
import type SenderEmailAddressSummary from 'embercom/objects/inbox/sender-email-address-summary';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import { type ReplyChannel, replyChannelIsChat } from 'embercom/objects/inbox/composer-pane';
import { isEmpty } from '@ember/utils';

interface Args {
  admin: AdminWithPermissions;
  sender: SenderEmailAddressSummary | AdminWithPermissions;
  replyChannel: ReplyChannel;
  onSenderChange: (sender: any) => void;
  setCustomAddressExists: (customAddressExists: boolean) => void;
}

interface Signature {
  Args: Args;
}

export default class SenderSelector extends Component<Signature> {
  @service declare inboxApi: InboxApi;

  @tracked senderEmailAddresses: (SenderEmailAddressSummary | AdminWithPermissions)[] = [];
  @tracked isLoading = false;
  @tracked senderEmailFilter = '';

  readonly replyChannelIsChat = replyChannelIsChat;

  popover?: { hide: () => void; show: () => void };

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadSenderEmailAddresses();
  }

  async loadSenderEmailAddresses() {
    this.isLoading = true;
    let customEmailAddresses = await this.inboxApi.fetchSenderEmailAddresses();
    this.args.setCustomAddressExists(!!customEmailAddresses.length);
    this.senderEmailAddresses = [this.args.admin, ...customEmailAddresses];
    this.isLoading = false;
  }

  @action setPopover(popover: { hide: () => void; show: () => void }) {
    this.popover = popover;
  }

  @action changeSender(newSender: any) {
    if (newSender.verified === false) {
      return;
    }

    this.args.onSenderChange(newSender);
    this.popover?.hide();
    this.senderEmailFilter = '';
  }

  get currentReplyChannelIsChat(): boolean {
    return this.replyChannelIsChat(this.args.replyChannel);
  }

  get filteredSenderEmail() {
    if (isEmpty(this.senderEmailFilter)) {
      return this.senderEmailAddresses;
    }
    let regexp = new RegExp(this.senderEmailFilter, 'i');
    return this.senderEmailAddresses.filter(
      (sender) => (sender.email && regexp.test(sender.email)) || regexp.test(sender.name),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::NewConversation::SenderSelector': typeof SenderSelector;
    'inbox2/new-conversation/sender-selector': typeof SenderSelector;
  }
}
