/* import __COLOCATED_TEMPLATE__ from './required-attributes-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { emptyRequiredAttributesForConversation } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type ConditionalAttributesService from 'embercom/services/conditional-attributes-service';
import type Session from 'embercom/services/session';
import type ConversationUpdates from 'embercom/services/conversation-updates';

export interface RequiredAttributesPanelArgs {
  conversation: ConversationRecord;
  descriptors: ConversationAttributeDescriptor[];
  hidePanel: () => void;
  updateAttributesAndCloseConversation: (
    conversation: ConversationRecord,
    attributes: ConversationAttributeSummary[],
  ) => Promise<void>;
}

interface Signature {
  Args: RequiredAttributesPanelArgs;
}

export default class RequiredAttributesPanel extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare conditionalAttributesService: ConditionalAttributesService;
  @service declare session: Session;

  @ref('panel') declare panel: HTMLDivElement;

  @tracked attributes = this.getRequiredAttributes();
  temporaryUpdates: Record<number, ConversationAttributeSummary> = {};

  attributeValues() {
    // apply any updates in the conversation updates service
    let updates = this.conversationUpdates.updatesFor(this.args.conversation.id);
    updates.forEach((update) => {
      update.apply(this.args.conversation);
    });

    return (
      this.args.conversation.attributes?.reduce(
        (byIds, attribute) => {
          byIds[attribute.descriptor.id] = attribute;
          return byIds;
        },
        {} as Record<string, ConversationAttributeSummary>,
      ) ?? {}
    );
  }

  get conditionalAttributesEvaluator() {
    return this.conditionalAttributesService.initialize({
      conversation: this.args.conversation,
      descriptors: this.args.descriptors,
    });
  }

  getRequiredAttributes() {
    if (this.session.workspace.canUseConditionalAttributes) {
      return this.conditionalRequiredAttributes();
    }

    return emptyRequiredAttributesForConversation(this.args.conversation, this.args.descriptors);
  }

  conditionalRequiredAttributes() {
    let attributeValues = this.attributeValues();
    let visibleAttributes = this.conditionalAttributesEvaluator
      .visibleAttributes()
      ?.filter(
        (descriptor: ConversationAttributeDescriptor) =>
          !descriptor.archived &&
          !descriptor.hideFromInboxSidebar &&
          descriptor.isVisibleToTeamId(this.args.conversation.teamAssignee?.id),
      );

    let nonConditionalDescriptors = visibleAttributes.filter(
      (descriptor) => !descriptor.isDependentAttribute,
    );

    let conditionalDescriptors = visibleAttributes
      .filter((descriptor) => {
        let tempUpdate = this.temporaryUpdates[descriptor.id as number];
        let hasValue = attributeValues[descriptor.id]?.value;

        // we want to show conditional attributes that are empty or
        // that are currently being updated
        // and that should be required
        return (
          (descriptor.isDependentAttribute && !hasValue && descriptor.required) ||
          this.hasNonEmptyValue(tempUpdate)
        );
      })
      .map((descriptor) => {
        let value = attributeValues[descriptor.id]?.value;
        return new ConversationAttributeSummary(
          value,
          descriptor as ConversationAttributeDescriptor,
        );
      });

    let requiredAttributes = emptyRequiredAttributesForConversation(
      this.args.conversation,
      nonConditionalDescriptors,
    );

    return this.orderAttributes(
      requiredAttributes.concat(conditionalDescriptors).uniqBy('descriptor.id'),
    );
  }

  hasNonEmptyValue(attribute: ConversationAttributeSummary) {
    if (!attribute) {
      return false;
    }

    return attribute.value !== null && attribute.value !== undefined && attribute.value !== '';
  }

  orderAttributes(attributes: ConversationAttributeSummary[]) {
    let order = new Map(
      this.args.descriptors.map((descriptor) => [descriptor.id, descriptor.order]),
    );

    return attributes.sort((a, b) => {
      let orderA = order.get(a.descriptor.id) ?? Infinity;
      let orderB = order.get(b.descriptor.id) ?? Infinity;
      return orderA - orderB;
    });
  }

  get canCloseConversation() {
    if (!this.attributes) {
      return false;
    }

    return this.attributes.every((attribute) => !attribute.isValueEmpty);
  }

  get warningText() {
    if (this.args.conversation.isCustomerTicket) {
      return this.intl.t(
        'inbox.conversations-table.required-attributes-panel.warning-customer-ticket',
      );
    }

    return this.intl.t('inbox.conversations-table.required-attributes-panel.warning');
  }

  get closeButtonText() {
    if (this.args.conversation.isCustomerTicket) {
      return this.intl.t(
        'inbox.conversations-table.required-attributes-panel.button-customer-ticket',
      );
    }

    return this.intl.t('inbox.conversations-table.required-attributes-panel.button');
  }

  @action async onUpdateAttribute(attribute: ConversationAttributeSummary) {
    if (this.session.workspace.canUseConditionalAttributes) {
      return await this.updateAttributeOptimistically(attribute);
    }

    if (!this.args.conversation.hasAttributeForDescriptor(attribute.descriptor.id)) {
      this.args.conversation.addAttribute(attribute);
    }

    return this.inboxApi.updateAttribute(this.args.conversation.id, attribute);
  }

  async updateAttributeOptimistically(attribute: ConversationAttributeSummary) {
    this.conditionalAttributesEvaluator.beforeAttributeUpdateHook = () => {
      this.temporaryUpdates[attribute.descriptor.id as number] = attribute;
      this.attributes = this.getRequiredAttributes();
    };

    await this.conditionalAttributesEvaluator.updateAttribute(attribute);
  }

  @action async closeConversation() {
    if (!this.attributes || !this.canCloseConversation) {
      return;
    }
    let updatedAttributes = this.attributes.filter((attribute) => attribute.isUpdated);
    await this.args.updateAttributesAndCloseConversation(this.args.conversation, updatedAttributes);

    // can't hide before calling updateAttributesAndCloseConversation
    // as that tears down the panel, which clears args
    this.args.hidePanel();
  }

  @action handleDocumentClick(ev: PointerEvent) {
    let ignoredElements = [
      this.panel,
      document.querySelector('.popover__content'),
    ].compact() as HTMLElement[];

    if (ignoredElements.any((element) => element.contains(ev.target as HTMLElement))) {
      return;
    }

    this.args.hidePanel();
  }

  @action handleEnter() {
    this.closeConversation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::RequiredAttributesPanel': typeof RequiredAttributesPanel;
    'inbox2/required-attributes-panel': typeof RequiredAttributesPanel;
  }
}
